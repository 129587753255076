<template>
	<ul class="estate-features">
		<li v-for="(item, index) in content" :key="index" :class="item.class">
			<or-icon :type="item.icon" class="estate-features__icon" />
			<span class="estate-features__value">
				{{ item.title }}
			</span>
			<span class="estate-features__desc">
				{{ item.text }}
			</span>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		content: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style lang="scss">
.estate-features {
	display: flex;
	flex-wrap: wrap;

	li {
		display: flex;
		align-items: center;
		margin-right: 30px;
		margin-bottom: 25px;

		&:last-child {
			margin-right: 0;
		}

		&.feature-grey {
			color: #808080;
		}
	}

	&__icon {
		font-size: 25px;
	}

	&__value {
		display: inline-block;
		margin-left: 15px;
		margin-right: 5px;
		font-size: 16px;
    line-height: 14px;
		font-weight: 700;
		color: #c99d56;
	}

	&__desc {
		font-size: 12px;
	}
}
</style>
